<template>
    <div class="news" v-if="translates && translates[langUrl]">
        <div class="news__title"> {{ title }}</div>
        <div class="news__container">
            <template v-for="(item, i) in showedNews">
                <div 
                    :key="i"
                    class="news__item"
                    @click="open( item.code )">
                    <div class="news__item__picture" :style="`background-image: url(${ imageSrc(item.picture) })` "></div>
                    <div class="news__item__text">
                        <!-- <div class="news__item__date">{{ postTime(item.createdAt) }}</div> -->
                        <div class="news__item__name">{{ item.title }}</div>
                        <div class="news__item__description">{{ item.preview }}</div>
                        <div class="news__item__more">{{ translates[langUrl].news_detail[lang] }}</div>
                    </div>
                </div>
            </template>
        </div>
        <BaseButton
            style="margin-top: 30px;"
            class="main-page__button-width"
            @click="newsList">{{ translates[langUrl].showMore[lang] }}
        </BaseButton>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import moment from '@/plugins/moment';
    import BaseButton from './BaseButton.vue'


    export default {
        name: 'NewsPreview',
        components: {
            BaseButton
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            needNews: {
                type: Number,
                default: 3
            }
        },
        data: () => ({
            imageSrc,
            langUrl: 'News'
        }),
        computed: {
            ...mapState('news', {
                news: state => state.entities
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            showedNews() {
                return this.news.slice(0, this.needNews)
            }
        },
        async mounted() {
            await this.getNews();
        },
        methods: {
            async getNews() {
                await store.dispatch('news/fetch', {});
            },
            postTime(date) {
                return moment(date).calendar()
            },
            async open(code) {
                await store.commit('news/CLEAR_ENTITY');
                await this.$router.push({ name: 'news', params: { code } });
                // await store.dispatch('news/get', { id });
            },
            async newsList() {
                await this.$router.push({ name: 'news-list' });
            },
        }
    }


</script>

<style lang="scss">
.news {
    margin: 70px auto 0;
    @media all and (max-width: 1440px) {
        margin: 70px 20px 0;
    }
    @media all and (max-width: 768px) {
        margin: 30px 20px 0;
    }
        @media all and (min-width: 1400px) {
            max-width: 1400px;
            margin: 70px auto 0;
        }
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    @media all and (max-width: 768px) {
        margin: 30px 20px 0;
    }
    &__title{
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
    }
    &__container{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-bottom: 10px;
        margin-top: 30px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            font-size: 16px;
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
        border-radius: 12px;
        overflow: hidden;
        width: 445px;
        margin-right: 33px;
        &:last-of-type {
            margin-right: 0;
        }
        @media all and (max-width: 768px) {
            min-width: 335px;
            width: 100%;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &__picture {
            width: 100%;
            height: 180px;
            background-position: center;
            background-size: cover;
        }
        &__text {
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
        }
        &__date {
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #93A3B4;
            min-height: 21px;
        }
        &__name {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #273155;
            text-align: left;
            margin-top: 12px;
            min-height: 48px;
        }
        &__description {
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
            text-align: left;
            margin-top: 8px;
            height: 95px;
            overflow: hidden;
        }
        &__more {
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #D80765;
            margin-top: 10px;
            cursor: pointer;
        }
    }
    &.column {
        margin: 70px 0 auto auto;
        @media all and (max-width: 1430px) {
            margin: 70px auto 0;
        }
        @media all and (max-width: 768px) {
            margin: 70px auto 0;
        }
        .news__container {
            flex-direction: column;
        }
        .news__item {
            margin-right: 0;
            min-width: 326px;
            width: 326px;
            margin-bottom: 20px;
        }
        .base-button {
            width: 100%;
        }
    }
}
</style>